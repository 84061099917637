let __system = {
  __activeid: "",
  __menu: [
  {
    pageFlag: false,
    id: "syc",
    title: "数据同步",
    icon: "el-icon-menu",
    children: [
    {
      pageFlag: false,
      id: "sycbase",
      title: "基础设置",
      icon: "el-icon-menu",
      children: [
      {
        pageFlag: true,
        id: "account",
        title: "数据库设置",
        name: "account",
        icon: "el-icon-document",
        component: "views/syc/Account.vue",
        visible:true

      },
      {
        pageFlag: true,
        id: "type",
        title: "同步类型设置",
        name: "type",
        icon: "el-icon-document",
        component: "views/syc/Type.vue"
        ,visible:true
      },
      {
        pageFlag: true,
        id: "detail",
        title: "同步明细设置",
        name: "detail",
        icon: "el-icon-document",
        component: "views/syc/Detail.vue"
        ,visible:true
      }]
    },
    {
        pageFlag: false,
        id: "syccommon",
        title: "日常业务",
        icon: "el-icon-menu",
        children: [
        {
          pageFlag: true,
          id: "partnerclass",
          title: "往来单位分类同步",
          name: "partnerclass",
          icon: "el-icon-document",
          component: "views/syc/PartnerClass.vue"
          ,visible:true
  
        },
        {
          pageFlag: true,
          id: "partner",
          title: "往来单位同步",
          name: "partner",
          icon: "el-icon-document",
          component: "views/syc/Partner.vue"
          ,visible:true
        },
        {
          pageFlag: true,
          id: "aaaccount",
          title: "会计科目同步",
          name: "aaaccount",
          icon: "el-icon-document",
          component: "views/syc/AaAccount.vue"
          ,visible:true
        },
        {
          pageFlag: true,
          id: "define01",
          title: "费用项目同步",
          name: "define01",
          icon: "el-icon-document",
          component: "views/syc/Define01.vue"
          ,visible:true
        }
      ]
      }
]
  }, ],
  __mdilist: [],
  set activeid(val) {
    this.__activeid = val;
  },
  get activeid() {
    return this.__activeid;
  },
  set menu(val) {
    this.__menu = val;
  },
  get menu() {
    return this.__menu;
  },
  set mdilist(val) {
    this.__mdilist = val;
  },
  get mdilist() {
    return this.__mdilist;
  }
}
//other
__system = {
  __activeid: "",
  __menu: [
    // {
    //   pageFlag: true,
    //   id: "test",
    //   title: "test",
    //   name: "test",
    //   icon: "el-icon-document",
    //   component: "views/syc/Test.vue"
    //   ,visible:true
    // },
  {
    pageFlag: false,
    id: "syc",
    title: "数据同步",
    icon: "el-icon-menu",
    children: [
    {
      pageFlag: false,
      id: "sycbase",
      title: "基础设置",
      icon: "el-icon-menu",
      children: [
      {
        pageFlag: true,
        id: "account",
        title: "数据库设置",
        name: "account",
        icon: "el-icon-document",
        component: "views/syc/Account.vue",
        visible:true

      },
      {
        pageFlag: true,
        id: "type",
        title: "同步类型设置",
        name: "type",
        icon: "el-icon-document",
        component: "views/syc/Type.vue"
        ,visible:true
      },
      {
        pageFlag: true,
        id: "detail",
        title: "同步明细设置",
        name: "detail",
        icon: "el-icon-document",
        component: "views/syc/Detail.vue"
        ,visible:true
      }]
    },
    {
        pageFlag: false,
        id: "syccommon",
        title: "日常业务",
        icon: "el-icon-menu",
        children: [
        {
          pageFlag: true,
          id: "partnerclass",
          title: "往来单位分类同步",
          name: "partnerclass",
          icon: "el-icon-document",
          component: "views/syc/PartnerClass.vue"
          ,visible:true
  
        },
        {
          pageFlag: true,
          id: "partner",
          title: "往来单位同步",
          name: "partner",
          icon: "el-icon-document",
          component: "views/syc/Partner.vue"
          ,visible:true
        },
        {
          pageFlag: true,
          id: "aaaccount",
          title: "会计科目同步",
          name: "aaaccount",
          icon: "el-icon-document",
          component: "views/syc/AaAccount.vue"
          ,visible:true
        },
        {
          pageFlag: true,
          id: "define01",
          title: "费用项目同步",
          name: "define01",
          icon: "el-icon-document",
          component: "views/syc/Define01.vue"
          ,visible:true
        }
        /**,
        {
          pageFlag: true,
          id: "inventoryclass",
          title: "存货分类同步",
          name: "inventoryclass",
          icon: "el-icon-document",
          component: "views/syc/InventoryClass.vue"
          ,visible: false
        },{
          pageFlag: true,
          id: "inventory",
          title: "存货同步",
          name: "inventory",
          icon: "el-icon-document",
          component: "views/syc/Inventory.vue"
          ,visible: false
        },{
          pageFlag: true,
          id: "bom",
          title: "bom同步",
          name: "bom",
          icon: "el-icon-document",
          component: "views/syc/Bom.vue"
          ,visible: false
        } */
      ],
      
      }
      /**,
      {
        pageFlag: false,
        id: "sysrpt",
        title: "报表",
        icon: "el-icon-menu",
        visible: false,
        children: [
        {
          pageFlag: true,
          id: "rpt1",
          title: "采购订单执行情况表",
          name: "rpt1",
          icon: "el-icon-document",
          component: "views/rpt/Rpt1.vue",
          visible: false
  
        }]}
       */
],
  }, ],
  __mdilist: [],
  set activeid(val) {
    this.__activeid = val;
  },
  get activeid() {
    return this.__activeid;
  },
  set menu(val) {
    this.__menu = val;
  },
  get menu() {
    return this.__menu;
  },
  set mdilist(val) {
    this.__mdilist = val;
  },
  get mdilist() {
    return this.__mdilist;
  }
}


let ld = (function (__system) {
  let _ld = {
    "system": __system,
  }
  return _ld;
})(__system)
export default ld